import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildJobListUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { Job, Jobs } from '../types';

export const loadJobList = (statuses: string[], userId?: string): Observable<Job[]> => {
    const fifteenDaysAgo = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000);
    const after = `${String(fifteenDaysAgo.getMonth() + 1).padStart(2, '0')}/${String(fifteenDaysAgo.getDate()).padStart(2, '0')}/${fifteenDaysAgo.getFullYear()}`;
    const obs = sendRetrieveRequest(buildJobListUrl(after, statuses, userId));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Jobs>) => {
            const jobs = convertToCamel<Job[]>(response.Job);
            return observableOf(jobs);
        }),
    );
};
